import React, { Component } from 'react'
import { connect } from 'react-redux'
import { creators } from './adjudicatorActions'
import common from '../common/common'

// Container that holds state of multiple patients and displays single patient
class AdjudicatorPatients extends Component {
  componentWillMount() {
    // Initialize data
    this.props.fetchHealthPlans(this.props.params.health_plan_id)
    this.props.fetchPatients(this.props.params.health_plan_id)
  }

  componentWillUnmount() {
     // Refresh health plans
    this.props.fetchHealthPlans()
    this.props.getPatientsCount()
  }

  render() {
    const { patients, current, loading, changePatient } = this.props

    const patient = patients[current]
    const component = (loading || !patient) ?  <common.components.CommonLoader/> : (
      <div className="patients">
        <PatientList patients={patients} changePatient={changePatient} current={current}/>
        <Patient {...this.props} patient={patient}/>
      </div>
    )

    return component
  }
}

// List of patients
const PatientList = (props) => {
  const { patients, current, changePatient } = props

  const states = (state, score) => ({
    'INIT': undefined,
    'SUBMITTING': <span className="icon material-icons orange-text">loop</span>,
    'SUBMITTED': <span className="icon green-text">{score}</span>,
    'ERROR': <span className="icon material-icons red-text">error</span>
  })[state]

  return (
    <ul className="patient-list collection">
    {
      patients.map((patient, index) => {
        const highlight = current == index ? 'active grey lighten-2 black-text' : ''
        const onPatientClick = () => { changePatient(index) }

        return (
          <li className={`collection-item ${highlight}`} onClick={onPatientClick} key={index}>
            {patient.demographics.ID}
            {states(patient.state, patient.score)}
          </li>
        )
      })
    }
    </ul>
  )
}

// Representation of single patient
const Patient = (props) => {
  const { patient, current, submitReview } = props
  const onReviewClick = (score) => { submitReview(patient.demographics.ID, current, score, patient.batchID) }

  return (
    <div className="patient">
      <Panel className="demographics" liClass="" type={Pairs} title="" data={patient.demographics}/>
      <Review onReviewClick={onReviewClick} state={patient.state} score={patient.score} />
      {patient.externalData && <Panel className="external-data" liClass="" type={Pairs} title="External Data" data={patient.externalData}/>}
      <Panel className="cost" liClass="chip" type={Pairs} title="Cost" data={patient.cost}/>
      <Panel className="utilization" liClass="chip" type={Pairs} title="PM/PM" data={patient.utilization}/>
      <Panel className="admissions" liClass="chip" type={Pairs} title="Admissions" data={patient.admissions}/>
      <Panel className="conditions" liClass="chip" type={Values} title="Conditions" data={patient.conditions}/>
      <Panel className="diagnoses" liClass="chip" type={Pairs} title="Diagnoses" data={patient.diagnoses}/>
      <div className="rx-chemo">
        <Panel className="prescriptions" liClass="chip" type={Pairs} title="Prescriptions" data={patient.prescriptions}/>
        <Panel className="chemo" liClass="chip" type={Values} title="Chemo" data={patient.chemo}/>
      </div>
    </div>
  )
}

// Panel for patient information
const Panel = (props) => {
  const { className, title, data, liClass } = props

  return (
    <div className={`${className} panel card`}>
      <div className="card-content">
        <span className="card-title">{title}</span>
        <props.type data={data} liClass={liClass}/>
      </div>
    </div>
  )
}

// Type of panel that displays patient information stored as list of values
const Values = (props) => {
  const { data, liClass } = props

  return (
    <ul>
    {
      data
        .filter((value) => value !== null)
        .map((value) => <li className={liClass} key={value}>{value}</li>)
    }
    </ul>
  )
}

// Type of panel that displays patient information stored as { key: value } pairs
const Pairs = (props) => {
  const { data, liClass } = props

  return (
    <ul>
    {
      Object.keys(data)
        .filter((key) => data[key] !== null)
        .map((key) => <li className={liClass} key={key}>{key}: <b>{data[key]}</b></li>)
    }
    </ul>
  )
}

// Review buttons for patient adjudication
const Review = (props) => {
  const { state, onReviewClick } = props

  const buttonClass = 'button waves-effect waves-light btn-large'
  const disabled = state != 'INIT'
  const submitted = state == 'SUBMITTED'
  const onReScoreClick = () => { onReviewClick(0) }
  const reviewButtonProps = [
    { score: 1, color: 'green darken-2', desc: 'Definitely a Fit' },
    { score: 2, color: 'green lighten-1', desc: 'Probably a Fit' },
    { score: 3, color: 'red lighten-1', desc: 'Probably Not a Fit' },
    { score: 4, color: 'red darken-2', desc: 'Definitely Not a Fit' },
    { score: 5, color: 'grey', desc: 'Not Impactable' },
  ]

  const reviewButtons = (
    reviewButtonProps.map((button) =>
      <ScoreButton
        key={button.score}
        score={button.score}
        color={button.color}
        desc={button.desc}
        buttonClass={buttonClass}
        disabled={disabled}
        onClick={onReviewClick}/>
    )
  )

  const reScoreButton = (
    <button
      className={`re-score blue ${buttonClass}`}
      onClick={onReScoreClick}>
      <span className="button-text">
        <span className="material-icons">undo</span>Re-Score
      </span>
    </button>
  )

  return (
    <div className="review panel card">
      <div className="card-content">
        <span className="review-info">Is this patient a good fit for Aspire's palliative care program?</span>
        <div className="buttons">
        {
          submitted ? reScoreButton : reviewButtons
        }
        </div>
      </div>
    </div>
  )
}

class ScoreButton extends Component {
  componentDidMount() {
    this.keypressScore = this.keypressScore.bind(this)

    // Enable keyboard control
    document.addEventListener('keypress', this.keypressScore)
  }

  componentWillUnmount() {
    // Disable keyboard control
    document.removeEventListener('keypress', this.keypressScore)
  }

  keypressScore(event) {
    const key = String.fromCharCode(event.which)
    const score = parseInt(key, 10)

    if (score == this.props.score) {
      this.button.click()
    }
  }

  render() {
    const { score, color, desc, buttonClass, disabled, onClick } = this.props

    const disabledClass = disabled ? 'disabled' : ''
    const className = `${buttonClass} ${disabledClass} ${color}`
    const onScoreClick = disabled ? undefined : () => { onClick(score) }

    return (
      <button
        className={className}
        onClick={onScoreClick}
        ref={(b) => { this.button = b }}>
        {score}<br/>
        <span className="desc">{desc}</span>
      </button>
    )
  }
}

// Connected adjudicator patients redux component
const mapStateToProps = (state) => {
  return {
    patients: state.adjudicator.patients.patients,
    healthPlan: state.adjudicator.healthPlans.healthPlans[state.adjudicator.healthPlans.current],
    current: state.adjudicator.patients.current,
    loading: state.adjudicator.patients.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHealthPlans: (healthPlanID) => {
      dispatch(creators.fetchHealthPlans(healthPlanID))
    },
    fetchPatients: (healthPlanID) => {
      dispatch(creators.fetchPatients(healthPlanID))
    },
    submitReview: (patientID, index, score, batchID) => {
      dispatch(creators.submitReview(patientID, index, score, batchID))
    },
    changePatient: (index) => {
      dispatch(creators.changePatient(index))
    },
    getPatientsCount: () => {
      dispatch(creators.getPatientsCount())
    }
  }
}

export const AdjudicatorPatientsContainer = connect(mapStateToProps, mapDispatchToProps)(AdjudicatorPatients)
