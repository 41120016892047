import React from 'react'
import keycloak from '../common/keycloak';

// Left pad string with with characters
const leftPad = (string, char, length) => {
  string = String(string)
  return char.repeat(length).substring(0, length - string.length) + string
}

// Format Date object
const formatDate = (inDate) => {
  const date = new Date(inDate)
  const year = date.getFullYear()
  const month = leftPad(date.getMonth() + 1, '0', 2)
  const day = leftPad(date.getDate(), '0', 2)
  const hours = leftPad(date.getHours(), '0', 2)
  const minutes = leftPad(date.getMinutes(), '0', 2)
  const seconds = leftPad(date.getSeconds(), '0', 2)

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

// Return new array with attributes of item assigned at index
const arrayAssign = (array, index, item) => {
  return [
    ...array.slice(0, index),
    Object.assign({}, array[index], item),
    ...array.slice(index + 1)
  ]
}

// Return new array with item at index removed
const arrayRemove = (array, index) => {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ]
}

// Get index of item in array of objects given key and value
const itemIndex = (items, key, value) => {
  return items.findIndex((item) => item[key] == value)
}

// Get next state based on action
const next = (state, handlers, type) => {
  const handler = handlers[type]
  return handler ? Object.assign({}, state, handler()) : state
}

// Bind props to React component
const bindProps = (Component, newProps) => {
  return (p) => <Component {...Object.assign({}, p, newProps)} />
}

// Gather up items in object for each prop given extract function
const gather = (obj, extract) => {
  const filteredObj = _.pickBy(obj, extract)
  const gatheredObj = _.mapValues(filteredObj, extract)

  return gatheredObj
}

// Dismiss Materialize toast
const dismissToast = (id) => {
  Vel($(`.toast.${id}`),
    { "opacity": 0, marginTop: '-40px' },
    {
      duration: 375, easing: 'easeOutExpo', queue: false,
      complete: function () { this[0].parentNode.removeChild(this[0]) }
    }
  )
}

// Intercept action with confirmation dialog
const confirmAction = (action) => {
  return () => {
    const confirmed = confirm('Are you sure you want to proceed?')

    if (confirmed) {
      action()
    }
  }
}

// Get current window location parts
const getLocation = () => {
  return {
    base: `${window.location.origin}${window.location.pathname}`,
    hash: window.location.hash
  }
}

// Perform asynchronous network request
const request = (method, url, body) => {
  return keycloak.refreshToken()
    .then(() => (new Request(`${url}`, {
        method: method,
        mode: 'cors',
        body: JSON.stringify(body),
        headers: new Headers({
          'userId': keycloak.userId,
          'email': keycloak.email,
          'Authorization': `Bearer ${keycloak.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      })
    ))
    .then(fetch)
    // Handle response status
    .then((response) => {
      if (response.ok) {
        return response
      } else if (response.status == 401) {
        throw new Error('Unauthorized.  Please login.')
      } else {
        throw new Error(response.statusText)
      }
    })
    // Parse JSON object if content type is JSON
    .then((response) => {
      const contentType = response.headers.get("content-type")
      if (contentType && contentType.includes('application/json')) {
        return response.json()
      } else {
        return response.text()
      }
    })
}

// Enrollment statuses
const status = {
  DEFINITE_MATCH: 310,
  PROBABLE_MATCH: 320,
  PROBABLE_REJECTION: 330,
  DEFINITE_REJECTION: 340
}

export const util = {
  leftPad,
  formatDate,
  arrayAssign,
  arrayRemove,
  itemIndex,
  next,
  bindProps,
  gather,
  dismissToast,
  confirmAction,
  getLocation,
  request,
  status
}
