import React, { Component } from 'react'
import { connect } from 'react-redux'
import { creators } from './adjudicatorActions'
import common, { util } from '../common/common'

class AdjudicatorHistory extends Component {
  componentWillMount() {
    const { submitReview } = this.props

    // Initialize data
    this.props.fetchHistory()

    const states = {
      'INIT': <span className="icon material-icons">undo</span>,
      'SUBMITTING': <span className="icon material-icons orange-text">loop</span>,
      'ERROR': <span className="icon material-icons red-text">error</span>
    }
    const undoButton = function() {
      return states[this.row.state]
    }
    const onReScoreClick = function() {
      if (this.row.state == 'INIT') {
        submitReview(this.row.id, this.index, 0, this.row.batch, true)
      }
    }

    this.className = 'table bordered highlight'
    this.filters = [
      { id: '1', label: 'Filter Health Plan', columns: ['healthPlan'] },
      { id: '2', label: 'Filter Adjudicator', columns: ['adjudicator'] },
      { id: '3', label: 'Filter Name', columns: ['firstName', 'lastName'] }
    ]
    this.columns = [
      { id: 're-score', 'width': '48px', label: '', transform: undoButton, onClick: onReScoreClick },
      { id: 'score', 'width': '5%', label: 'Score' },
      { id: 'id', 'width': '5%', label: 'ID' },
      { id: 'firstName', 'width': '10%', label: 'First' },
      { id: 'lastName', 'width': '15%', label: 'Last' },
      { id: 'healthPlan', 'width': '25%', label: 'Health Plan' },
      { id: 'adjudicator', 'width': '25%', label: 'Adjudicator' },
      { id: 'date', 'width': '15%', label: 'Date', transform: util.formatDate }
    ]
  }

  render() {
    const { history, loading } = this.props

    const component = loading ? <common.components.CommonLoader/> : (
      <common.components.CommonTable className={this.className}
                                     filters={this.filters}
                                     columns={this.columns}
                                     data={history}/>
    )

    return component
  }
}

// Connected adjudicator health plans redux component
const mapStateToProps = (state) => {
  return {
    history: state.adjudicator.history.history,
    filters: state.adjudicator.history.filters,
    loading: state.adjudicator.history.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHistory: () => {
      dispatch(creators.fetchHistory())
    },
    submitReview: (patientID, index, score, batchID, history) => {
      dispatch(creators.submitReview(patientID, index, score, batchID, history))
    }
  }
}

export const AdjudicatorHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(AdjudicatorHistory)
