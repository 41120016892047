import React from 'react'
import { Route } from 'react-router'
import { components } from './adjudicatorComponents'
import { util } from '../common/common'
import { api } from 'urls'

// Generate adjudicator patients text
const adjudicatorPatientsText = (state) => {
  const healthPlan = state.healthPlans.healthPlans[state.healthPlans.current]
  const currentPatient = state.patients.current
  const patientsLength = state.patients.patients.length

  const healthPlanName = healthPlan ? healthPlan.name : ''
  const patientsStatus = patientsLength ? ` — Patient ${currentPatient + 1} of ${patientsLength}` : ``

  return `${healthPlanName}${patientsStatus}`
}

// Generate adjudicator patients link
const adjudicatorPatientsLink = (state) => {
  const healthPlan = state.healthPlans.healthPlans[state.healthPlans.current]
  const healthPlanID = healthPlan ? healthPlan.id : ''

  return paths.ADJUDICATOR_PATIENTS(healthPlanID)
}

const paths = {
  ADJUDICATOR: () => '/adjudicator',
  ADJUDICATOR_PATIENTS: (healthPlanID) => `/adjudicator/health_plan/${healthPlanID || ':health_plan_id'}`,
  ADJUDICATOR_HISTORY: () => '/adjudicator/history'
}

const endpoints = {
  ADJUDICATOR_HEALTH_PLANS: () => `${api}/adjudicator`,
  ADJUDICATOR_PATIENTS: (healthPlanID) => `${api}/adjudicator/health_plan/${healthPlanID}/patient`,
  ADJUDICATOR_PATIENTS_COUNT: () => `${api}/adjudicator/patients/count`,
  ADJUDICATOR_PATIENTS_SUBMIT: () => `${api}/adjudicator/patients/submit`,
  ADJUDICATOR_REVIEW: (patientID) => `${api}/adjudicator/health_plan/1/patient/${patientID}/review`,
  ADJUDICATOR_HISTORY: () => `${api}/adjudicator/history`
}

const links = (state) => ({
  [paths.ADJUDICATOR()]: {
    text: 'Physician Adjudicator',
    path: paths.ADJUDICATOR()
  },
  [paths.ADJUDICATOR_HISTORY()]: {
    text: 'Adjudication History',
    path: paths.ADJUDICATOR_HISTORY()
  },
  [paths.ADJUDICATOR_PATIENTS()]: {
    text: adjudicatorPatientsText(state.adjudicator),
    path: adjudicatorPatientsLink(state.adjudicator)
  }
})

const route = (
  <Route key={paths.ADJUDICATOR()} path={paths.ADJUDICATOR()} component={components.AdjudicatorHealthPlansContainer}>
    <Route path={paths.ADJUDICATOR_HISTORY()} component={components.AdjudicatorHistoryContainer}/>
    <Route path={paths.ADJUDICATOR_PATIENTS()} component={components.AdjudicatorPatientsContainer}/>
  </Route>
)

export const routes = {
  paths,
  endpoints,
  links,
  route
}
