import { routes } from './adjudicatorRoutes'
import { util } from '../common/common'

export const actions = {
  FETCH_HEALTH_PLANS: 'ADJUDICATOR_FETCH_HEALTH_PLANS',
  INIT_HEALTH_PLANS: 'ADJUDICATOR_INIT_HEALTH_PLANS',
  FETCH_PATIENTS: 'ADJUDICATOR_FETCH_PATIENTS',
  INIT_PATIENTS: 'ADJUDICATOR_INIT_PATIENTS',
  FETCH_HISTORY: 'ADJUDICATOR_FETCH_HISTORY',
  INIT_HISTORY: 'ADJUDICATOR_INIT_HISTORY',
  SUBMIT_REVIEW: 'ADJUDICATOR_SUBMIT_REVIEW',
  PROCESS_REVIEW: 'ADJUDICATOR_PROCESS_REVIEW',
  CHANGE_PATIENT: 'ADJUDICATOR_CHANGE_PATIENT',
  GET_PATIENTS_COUNT: 'ADJUDICATOR_GET_PATIENTS_COUNT',
  SUBMIT_SCORED_PATIENTS: 'ADJUDICATOR_SUBMIT_SCORED_PATIENTS'
}

// Initialize health plans from server
const fetchHealthPlans = (healthPlanID) => {
  return (dispatch) => {
    dispatch({ type: actions.FETCH_HEALTH_PLANS, healthPlanID })

    util.request('GET', routes.endpoints.ADJUDICATOR_HEALTH_PLANS())
    .then((response) => {
      dispatch({ type: actions.INIT_HEALTH_PLANS, healthPlans: response, healthPlanID })
    })
  }
}

// Initialize patients from server
const fetchPatients = (healthPlanID) => {
  return (dispatch) => {
    dispatch({ type: actions.FETCH_PATIENTS, healthPlanID })

    util.request('GET', routes.endpoints.ADJUDICATOR_PATIENTS(healthPlanID))
    .then((response) => {
      dispatch({ type: actions.INIT_PATIENTS, patients: response })
    })
  }
}

// Initialize history from server
const fetchHistory = () => {
  return (dispatch) => {
    dispatch({ type: actions.FETCH_HISTORY })

    util.request('GET', routes.endpoints.ADJUDICATOR_HISTORY())
    .then((response) => {
      dispatch({ type: actions.INIT_HISTORY, history: response })
    })
  }
}

// Submit review for patient
const submitReview = (patientID, index, score, batchID, history) => {
  const timeout = 1500
  const toastParams = {
    // Submitting new review
    true: {
      true: [`<span>Submitted score of <b>${score}</b> for patient <b>${patientID}</b></span>`, timeout, 'success'],
      false: [`<span>Could not submit score for patient <b>${patientID}</b></span>`, timeout * 3, 'error']
    },
    // Reversing previous review
    false: {
      true: [`<span>Reversed review for patient <b>${patientID}</b></span>`, timeout, 'success'],
      false: [`<span>Could not reverse review for patient <b>${patientID}</b></span>`, timeout * 3, 'error']
    }
  }

  return (dispatch) => {
    dispatch({ type: actions.SUBMIT_REVIEW, index, history })

    const data = { score, batch_id: batchID }
    const processReview = (success) => {
      const message = toastParams[score > 0][success][0]
      const displayTimeout = toastParams[score > 0][success][1]
      const cssClass = toastParams[score > 0][success][2]

      window.M.toast({html: message, displayLength: displayTimeout, classes: cssClass})
      dispatch({ type: actions.PROCESS_REVIEW, index, success, score, history })
    }

    util.request('POST', routes.endpoints.ADJUDICATOR_REVIEW(patientID), data)
    .then((response) => { processReview(true) })
    .catch((error) => { processReview(false) })
  }
}

// Change current patient
const changePatient = (index) => {
  return { type: actions.CHANGE_PATIENT, index }
}

// Get scored patients count
const getPatientsCount = () => {
  return (dispatch) => {
    util.request('GET', routes.endpoints.ADJUDICATOR_PATIENTS_COUNT())
    .then((response) => {
      dispatch({ type: actions.GET_PATIENTS_COUNT, count: response.count })
    })
  }
}

// Submit scored patients
const submitScoredPatients = () => {
  return (dispatch) => {
    dispatch({ type: actions.SUBMIT_SCORED_PATIENTS })

    util.request('POST', routes.endpoints.ADJUDICATOR_PATIENTS_SUBMIT())
    .then((response) => {
      dispatch(getPatientsCount())
    })
  }
}

export const creators = {
  fetchHealthPlans,
  fetchPatients,
  fetchHistory,
  submitReview,
  changePatient,
  getPatientsCount,
  submitScoredPatients
}
