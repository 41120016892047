import Keycloak from 'keycloak-js'

export class KeycloakWrapper {
  _keycloak = Keycloak()

  async init() {
    await this._keycloak.init({ onLoad: 'login-required' })
  }

  /**
   * @returns {Promise<object>} promise that resolves with the user's parsed JWT.
   *  If the current JWT has not expired, it is returned immediately; otherwise,
   *  we attempt to renew it. If that renewal fails, the promise is rejected.
   */
  async refreshToken() {
    try {
      await this._keycloak.updateToken()
      return this.parsedToken
    } catch (e) {
      throw new Error('Failed to refresh auth token')
    }
  }

  /**
   * Logs the user out.
   *
   * @returns {Promise} indicates whether the logout operation was successful.
   */
  async logout() {
    await this._keycloak.logout()
  }

  /**
   * Returns the user's JWT. Written as a getter because the keycloak
   * sdk is stateful, so to ensure we always get the correct token we always
   * reference the instance.
   *
   * @returns {string} the current JWT
   */
  get token() {
    return this._keycloak.token
  }

  /**
   * @returns {Promise<object>} the parsed JWT token
   */
  get parsedToken() {
    return this._keycloak.idTokenParsed
  }

  get userId() {
    return this.parsedToken.user_id
  }

  get email() {
    return this.parsedToken.email
  }
}

export default new KeycloakWrapper()
