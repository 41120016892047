const paths = {
  HOME: () => '/'
}

const links = (state) => ({
  [paths.HOME()]: {
    text: 'Enrollment Toolbox',
    path: paths.HOME()
  }
})

export const routes = {
  paths,
  links
}
