import { routes } from './adjudicatorRoutes'
import { components } from './adjudicatorComponents'
import { reducer, state } from './adjudicatorReducer'

const info = {
  label: 'Physician Adjudicator',
  link: routes.paths.ADJUDICATOR(),
  icon: 'gavel'
}

export default {
  info,
  routes,
  components,
  reducer,
  state
}
