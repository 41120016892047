import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { creators } from './adjudicatorActions'
import { routes } from './adjudicatorRoutes'
import common, { util } from '../common/common'

// List of health plans
class AdjudicatorHealthPlans extends Component {
  componentWillMount() {
    // Initialize data
    this.props.fetchHealthPlans()
    this.props.getPatientsCount()
  }

  render() {
    const { loading, submit, submitScoredPatients, patientsCount, healthPlans, children } = this.props

    const submitClass = submit ? '' : 'disabled'
    const onSubmitClick = submit ? util.confirmAction(submitScoredPatients) : undefined

    const component = loading ? <common.components.CommonLoader/> : (

      <div className="health-plans">

        <Link to={routes.paths.ADJUDICATOR_HISTORY()}
              className="button btn waves-effect waves-light blue-grey">
          <span className="button-text">
            <span className="material-icons">history</span>
            <span>History</span>
          </span>
        </Link>

        <a className={`button btn waves-effect waves-light green ${submitClass}`}
           onClick={onSubmitClick}>
          <span className="button-text">
            <span className="material-icons">done</span>
            <span>Submit ({patientsCount})</span>
          </span>
        </a>

        <ul className="collection">
        {
          healthPlans.map((healthPlan) =>
            <HealthPlan healthPlan={healthPlan} key={healthPlan.id}/>
          )
        }
        </ul>

      </div>

    )

    return children || component
  }
}

// Representation of single health plan, with link to its corresponding patients
const HealthPlan = (props) => {
  const { healthPlan } = props

  return (
    <Link
      className="collection-item blue-text"
      to={routes.paths.ADJUDICATOR_PATIENTS(healthPlan.id)}>
      {healthPlan.name}<span className="badge">{healthPlan.count}</span>
    </Link>
  )
}

// Connected adjudicator health plans redux component
const mapStateToProps = (state) => {
  return {
    healthPlans: state.adjudicator.healthPlans.healthPlans,
    loading: state.adjudicator.healthPlans.loading,
    patientsCount: state.adjudicator.healthPlans.patientsCount,
    submit: state.adjudicator.healthPlans.submit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHealthPlans: (healthPlanID) => {
      dispatch(creators.fetchHealthPlans(healthPlanID))
    },
    getPatientsCount: () => {
      dispatch(creators.getPatientsCount())
    },
    submitScoredPatients: () => {
      dispatch(creators.submitScoredPatients())
    }
  }
}

export const AdjudicatorHealthPlansContainer = connect(mapStateToProps, mapDispatchToProps)(AdjudicatorHealthPlans)
