import React, { Component } from 'react'
import { util } from '../common/common'

// Example Usage
//
// class = ... // Class(es) to be applied to table
//
// filters = [ { id: 'XXXX', label: 'XXXX', columns: ['XXXX', 'XXXX'] }, ... ] // Filters to filter data by
//
// columns = [
//   {
//     id: 'XXXX', // Column identifier (required)
//     label: 'XXXX', // Column label (required)
//     width: '##%', // Column width
//     default: XXXX, // Default when cell == null
//     transform: function(cell) { this.row, this.col, this.rowIndex }, // Function to transform cell
//     onClick: function(event) { this.row, this.col, this.rowIndex } // Function to handle cell clicks
//   },
//   ...
// ]
//
// data = [
//   [X, X, X, ...],
//   [X, X, X, ...],
//   [X, X, X, ...],
//   ...
// ]
//
// <CommonTable class={class} filters={filters} columns={columns} data={data}/>

// Data table
export class CommonTable extends Component {
  constructor(props) {
    super(props)

    this.state = { filters: props.filters }
  }

  // Check whether cells pass filter
  passFilter(cells, filter) {
    const tokens = (filter === undefined ? '' : filter).toLowerCase().split(' ')

    return tokens.reduce((pass, token) =>
      pass && String(cells === null ? '' : cells).toLowerCase().includes(token),
      true)
  }

  // Filter data given filters
  filterData(data, filters, columns) {
    // Store original index for each row
    const indexedData = data.map((row, index) =>
      Object.assign({}, row, { index })
    )
    // Filter rows of data based on filters
    const filteredData = indexedData.filter((row) =>
      // Every filter must pass
      filters.every((filter) =>
        // Concatenate all cells and check if passes filter
        this.passFilter(filter.columns.reduce((cells, col) =>
          cells + row[col], ''),
          filter.value)
      )
    )

    return filteredData
  }

  // Filter change handler
  onFilterChange(id, value) {
    const filter = Object.assign({}, this.state.filters[id], { value })
    const filters = util.arrayAssign(this.state.filters, id, filter)

    this.setState(Object.assign({}, this.state, { filters }))
  }

  render() {
    const { className, columns, data } = this.props
    const { filters } = this.state

    const filteredData = this.filterData(data, filters, columns)

    return (
      <div className={className}>
        <div className="filters">
        {
          filters.map((filter, index) => {
            const onChange = (event) => this.onFilterChange(index, event.target.value)
            return <Filter filter={filter} onChange={onChange} key={filter.id}/>
          })
        }
        </div>

        <table>
          <thead>
            <tr>
            {
              columns.map((col, index) =>
                <Column col={col} key={index}/>
              )
            }
            </tr>
          </thead>

          <tbody>
          {
            filteredData.map((row, rowIndex) =>
              <Row {...this.props} row={row} key={rowIndex}/>
            )
          }
          </tbody>
        </table>
      </div>
    )
  }
}

const Filter = (props) => {
  const { filter, onChange } = props

  return (
    <div className="filter input-field" key={filter.id}>
      <input type="text" id={`filter-${filter.id}`} value={filter.value} onChange={onChange}/>
      <label htmlFor={`filter-${filter.id}`}>{filter.label}</label>
    </div>
  )
}

const Column = (props) => {
  const { col } = props

  return (
    <th width={col.width} key={col.id} data-field={col.id}>{col.label}</th>
  )
}

const Row = (props) => {
  const { row, columns } = props

  const createRow = (col, colIndex) => {
    const context = { row, col: col, index: row.index }
    let cell = row[col.id]
    let onClick, style

    // Apply onClick handler and pointer style
    if (col.onClick) {
      onClick = col.onClick.bind(context)
      style =  { cursor: 'pointer' }
    }

    // Apply column default
    if (col.default) {
      cell = cell !== null ? cell : col.default
    }
    // Apply column transform
    if (col.transform) {
      cell = col.transform.call(context, cell)
    }

    return <td key={colIndex} onClick={onClick} style={style}>{cell}</td>
  }

  return (
    <tr>
      {columns.map(createRow)}
    </tr>
  )
}
