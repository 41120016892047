import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

// Container class for entire application
const MainApp = (props) => {
  const { links, routes, children } = props

  return (
    <div className="app">

      <NavBar {...props}/>

      <div className="content">
        {children}
      </div>

    </div>
  )
}

// Navigation bar to track location within application
const NavBar = (props) => {
  const { routes, links } = props

  const filterIndexRoutes = (route) => route.path

  return (
    <nav className="nav-bar blue">
      <div className="nav-wrapper">
      {
        routes.filter(filterIndexRoutes).map((route, index) => {
          const link = links[route.path]
          return <Link to={link.path} key={index} className="breadcrumb">{link.text}</Link>
        })
      }
      </div>
    </nav>
  )
}

// Gather links for nav bar
const generateLinks = (apps, state) => {
  const filterLinks = (app) => apps[app].routes.links
  const flattenLinks = (links, app) => {
    return Object.assign(links, apps[app].routes.links(state))
  }

  return Object.keys(apps).filter(filterLinks).reduce(flattenLinks, {})
}

// Connected app redux component with app links
export const MainAppContainer = (apps) =>{
  const mapStateToProps = (state) => {
    return {
      links: generateLinks(apps, state)
    }
  }

  return connect(mapStateToProps)(MainApp)
}
