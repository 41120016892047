import React from 'react'
import { Route, IndexRedirect } from 'react-router'
import components from './components'
import apps from './apps'
import { util } from './common/common'

const routes = util.gather(apps, (app) => app.routes)
const routeComponents = _.values(_.mapValues(routes, (app) => app.route))

export const root = (
  <Route path={routes.main.paths.HOME()} component={components.main.MainAppContainer(apps)}>
    <IndexRedirect to={routes.adjudicator.paths.ADJUDICATOR()}/>
    {routeComponents}
  </Route>
)

export default routes
