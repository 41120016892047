// import Keycloak from 'keycloak-js'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, hashHistory } from 'react-router'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import keycloak from './common/keycloak'
import reducers from './reducers'
import { root } from './routes'

// Create store
const store = createStore(reducers.root, reducers.state, applyMiddleware(thunk))
window.store = store // Make store accessible in console

keycloak.init().finally(() =>
  ReactDOM.render((
    <Provider store={store}>
      <Router history={hashHistory}>
        {root}
      </Router>
    </Provider>
  ), document.getElementById('root'))
)
