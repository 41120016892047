import { combineReducers } from 'redux'
import { actions } from './adjudicatorActions'
import { util } from '../common/common'

const healthPlans = (state = [], action) => {
  const handlers = {

    [actions.FETCH_HEALTH_PLANS]: () => {
      return action.healthPlanID ?
        // Called from patients page, set current health plan
        { current: util.itemIndex(state.healthPlans, 'id', action.healthPlanID) } :
        // Called from health plans page, reset health plans
        { healthPlans: [], loading: true }
    },

    [actions.INIT_HEALTH_PLANS]: () => {
      const current = action.healthPlanID ?
        // Called from patients page, set current health plan
        util.itemIndex(action.healthPlans, 'id', action.healthPlanID) :
        // Called from health plans page, reset current health plan
        undefined

      return { healthPlans: action.healthPlans, current, loading: false }
    },

    [actions.GET_PATIENTS_COUNT]: () => {
      const submit = action.count > 0
      return { patientsCount: action.count, submit }
    },

    [actions.SUBMIT_SCORED_PATIENTS]: () => {
      return { submit: false }
    }

  }

  return util.next(state, handlers, action.type)
}

const patients = (state = [], action) => {
  const handlers = {

    [actions.FETCH_PATIENTS]: () => {
      // Reset patients
      return { patients: [], current: 0, loading: true }
    },

    [actions.INIT_PATIENTS]: () => {
      // Set patients
      return { patients: action.patients, loading: false }
    },

    [actions.SUBMIT_REVIEW]: () => {
      if (!action.history) {
        // Change state of current patient to submitting
        const patients = util.arrayAssign(state.patients, state.current,
          { state: 'SUBMITTING' })

        // Move to next patient if not undoing review
        const current = (state.patients[state.current].state == 'INIT') ?
          Math.min(state.current + 1, state.patients.length - 1) :
          state.current

        return { current, patients }
      }
    },

    [actions.PROCESS_REVIEW]: () => {
      if (!action.history) {
        // Change state of current patient based on success and score
        const patientState = action.success ?
          (action.score > 0 ? 'SUBMITTED' : 'INIT') :
          'ERROR'

        const patients = util.arrayAssign(state.patients, action.index,
          { state: patientState, score: action.score })

        return { patients }
      }
    },

    [actions.CHANGE_PATIENT]: () => {
      // Change current patient
      return { current: action.index }
    }

  }

  return util.next(state, handlers, action.type)
}

const history = (state = [], action) => {
  const handlers = {

    [actions.FETCH_HISTORY]: () => {
      return { loading: true }
    },

    [actions.INIT_HISTORY]: () => {
      // Set history
      return { history: action.history, loading: false }
    },

    [actions.SUBMIT_REVIEW]: () => {
      if (action.history) {
        // Change state of patient to submitting
        const history = util.arrayAssign(state.history, action.index,
          { state: 'SUBMITTING' })

        return { history }
      }
    },

    [actions.PROCESS_REVIEW]: () => {
      if (action.history) {
        const history = action.success ?
          // Remove patient if review submit successful
          util.arrayRemove(state.history, action.index) :
          // Show error if review submit unsuccessful
          util.arrayAssign(state.history, action.index, { state: 'ERROR' })

        return { history }
      }
    }

  }

  return util.next(state, handlers, action.type)
}

export const state ={
  healthPlans: {
    healthPlans: [],
    patientsCount: 0,
    submit: false,
    current: undefined,
    loading: false
  },
  patients: {
    patients: [],
    current: undefined,
    loading: false
  },
  history: {
    history: [],
    loading: false
  }
}

export const reducer = combineReducers({
  healthPlans,
  patients,
  history
})
